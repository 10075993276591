export const PROTECTED_WORLD_DOC_KEYS = [
  "actions",
  "documents",
  "entities",
  "entryways",
  "identities",
  "layers",
  "messages",
  "settings",
];
